import {
  trackPageViewV2,
  trackFormEvent,
  AnalyticsEvent,
} from "@/services/analytics-adapter";
import { GetHelpFABConfig } from "@/theme";
import { FaceIconBrandLarge } from "@/theme/icons";
import { convertPxToRem } from "@/utils";
import {
  isValid as isValidUNUID,
  OPTIONS as UNUIDValidatorOptions,
} from "@/utils/validators/common";
import { Stack, Typography, TextField, Button } from "@mui/material";
import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import GetHelpCTAButton from "@/components/GetHelpCTAButton";
import SwitchAuthIdentificationTypeModal, {
  ModalKeys,
} from "@/components/SwitchAuthIdentificationTypeModal";

interface CollectUNUIDProps {
  value: string;
  onSubmit: (UNUIDValue: string) => void;
  onSwitchAuthTypeHandler?: () => void;
}

export default function CollectUNUID(props: CollectUNUIDProps) {
  const { t } = useTranslation();
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [UNUIDValue, setUNUIDValue] = useState(props.value || "");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    updateNextButtonDisabledState();
  }, [UNUIDValue]);

  useEffect(() => {
    trackPageViewV2({
      pageName: "Sign up:Collect Unu ID",
      pageSubSection1: "Sign up",
      pageSubSection2: "Sign up:Collect Unu ID",
      pageCategory: "Sign up",
    });

    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Enter UNU ID",
    });
  }, []);

  function isValid(identificationValue: string) {
    if (identificationValue) {
      return isValidUNUID(identificationValue);
    }
    return false;
  }

  function updateNextButtonDisabledState() {
    setIsNextButtonEnabled(isValid(UNUIDValue));
  }

  function onUNUIDInput(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setUNUIDValue(value);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit(UNUIDValue);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  function onSwitchAuthTypeHandler() {
    if (props.onSwitchAuthTypeHandler) {
      props.onSwitchAuthTypeHandler();
    }

    setIsModalOpen(false);
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={6} justifyContent="space-between" sx={{ pb: 2 }}>
          <Stack spacing={2}>
            <FaceIconBrandLarge />
            <Typography variant="h3">{t("CollectUNUID.title")}</Typography>
          </Stack>

          <Stack sx={{ mb: 4, mt: 4 }}>
            <Typography variant="body1">
              {t("CollectUNUID.inputLabel")}
            </Typography>
            <TextField
              onInput={onUNUIDInput}
              value={UNUIDValue}
              inputProps={{
                autoComplete: "off",
                inputMode: "text",
                maxLength: UNUIDValidatorOptions.maxLength,
                "aria-label": t("CollectUNUID.inputLabel"),
              }}
              placeholder={t("CollectUNUID.inputPlaceholder")}
              sx={{ input: { fontSize: convertPxToRem(16) } }}
            />

            <Button
              sx={{ mt: 2, textAlign: "center" }}
              onClick={() => setIsModalOpen(true)}
              variant="text"
            >
              {t("CollectUNUID.switchToRSAIDLink")}
            </Button>
          </Stack>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
              pt={1}
            >
              <GetHelpCTAButton eventName="action.contactSupportFab" />
            </Stack>

            <Button
              type="submit"
              color="primary"
              disabled={!isNextButtonEnabled}
              aria-label={t("common.nextButton")}
            >
              {t("common.nextButton")}
            </Button>
          </Stack>
        </Stack>
      </form>
      <SwitchAuthIdentificationTypeModal
        extras={{
          onRatherSignInWithDifferentAuthType: onSwitchAuthTypeHandler,
          modalLabelKey: ModalKeys.WHAT_IS_AN_UNUID,
          authTypeLabel: ModalKeys.RSA_ID_OR_PASSPORT,
        }}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </>
  );
}
